.blockquote{
  font-size: 1.0em;
  width:80%;
  margin:5px 5px 5px 5px;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 50px;
  border-left:8px solid #78C0A8 ;
  line-height:1.0;
  position: relative;
  background:#EDEDED;
  overflow-wrap: break-word;
}

.blockquote::before{
  font-family:Arial;
  content: "\1F4CE";
  color:#78C0A8;
  font-size:1.5em;
  position: absolute;
  left: 10px;
  vertical-align: middle;
}

.blockquoteText:hover {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}